<template>
  <v-dialog
    v-model="show"
    max-width="600px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="roleId"
            class="body-2"
          >
            <i>#{{ roleId }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col
              md="6"
            >
              <validation-provider
                v-slot="{ errors }"
                vid="name"
              >
                <v-text-field
                  v-model="role.name"
                  :disabled="loading"
                  hide-details="auto"
                  label="Nome"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
           
          </v-row>

          <v-row class="mb-0 mt-7">
            <v-col class="text-left">
              <span class="display-1 font-weight-bold">Permissões</span>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="permissions"
              >
                <v-row no-gutters>
                  <v-col
                    v-for="permission in permissions"
                    md="6"
                    class="pb-0 pt-0"
                    :key="permission.id"
                  >
                    <v-checkbox
                      v-model="role.permissions"
                      class="mt-0"
                      hide-details="auto"
                      multiple
                      :label="permission.description"
                      :value="permission.id"
                      :error="errors.length > 0"
                    />
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col>
                    <div
                      v-if="errors.length > 0"
                      class="v-messages theme--light error--text mt-2"
                    >
                      {{ errors[0] }}
                    </div>
                  </v-col>
                </v-row>
              </validation-provider>
            </v-col>
          </v-row>

        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            v-text="'mdi-content-save'"
            left
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import rolesApi from '@/api/roles'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      roleId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        permissions: [],
        role: {
          permissions: []
        },
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      title () {
        return this.roleId ? 'Editar' : 'Adicionar'
      }
    },

    mounted () {
      this.loadPermissions()

      if (this.roleId) {
        this.loadRole()
      }
    },

    methods: {

      async loadRole () {
        try {
          this.loading = true

          const response = await rolesApi.get(this.roleId)

          const role = { ...response.data.role }
          
          const permissions = role.permissions.map((r) => r.id)
          role.permissions = permissions

          this.role = role
        } catch (e) {
          console.log(e)
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadPermissions () {
        try {
          this.loading = true

          const response = await rolesApi.listPermissions()

          this.permissions = response.data.permissions
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          const role = { ...this.role }

          if (this.roleId) {
            await rolesApi.update(this.roleId, role)
          } else {
            await rolesApi.insert(role)
          }

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: this.roleId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
          this.$emit('save')
        } catch (e) {
          console.log(e)
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
